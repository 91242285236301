/*
SCREENSIZE
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: May 25, 2022
UPDATE DATE: May 25, 2022
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: ScreenSize | Check your screen size!
PALETTE: -
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Regular.woff2') format('woff2'),
       url('./assets/font/Exo-Regular.woff') format('woff'),
       url('./assets/font/Exo-Regular.ttf') format('ttf'),
       url('./assets/font/Exo-Regular.eot') format('eot'),
       url('./assets/font/Exo-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Bold.woff2') format('woff2'),
       url('./assets/font/Exo-Bold.woff') format('woff'),
       url('./assets/font/Exo-Bold.ttf') format('ttf'),
       url('./assets/font/Exo-Bold.eot') format('eot'),
       url('./assets/font/Exo-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Light.woff2') format('woff2'),
       url('./assets/font/Exo-Light.woff') format('woff'),
       url('./assets/font/Exo-Light.ttf') format('ttf'),
       url('./assets/font/Exo-Light.eot') format('eot'),
       url('./assets/font/Exo-Light.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

html{
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  color: #999;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  color: #666;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  &:hover{
    color: #333;
  }
}

// a[disabled]{
//   pointer-events: none;
//   opacity: .5;
// }

button,
input{
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 12px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  // &:hover{}

  &:focus{
    outline: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

.Toastify__toast-container{
  bottom: 20px !important;
  // right: 21px !important;
  padding: 0 !important;

  .Toastify__toast{
    font-family: 'Exo', Helvetica, Arial, sans-serif !important;
    font-size: 12px;
    line-height: 16px;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .1);
    margin-top: 10px;
    margin-bottom: 0;
    padding: 15px 0 20px 0 !important;
    border-radius: 5px;
  
    .Toastify__close-button{
      width: 16px;
      margin: 0 10px 0 0;
    }

    .Toastify__progress-bar-theme--light{
      background: #999;
    }

    .Toastify__toast-body{
      padding: 0 10px 0 15px;

      > div{
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;

        > span{
          display: block;
          font-size: 12px;
          line-height: 16px;
          font-weight: normal;
        }
      }
    }
  }
}

.pad-top{
  height: 70px;
}

header{
  padding: 15px 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, .95);
  z-index: 3;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .1);
  
  > a{
    float: left;
    font-size: 20px;
    line-height: 40px;
    font-weight: bold;

    > svg{
      display: block;
      width: 164px;
      height: 40px;
    }
  }

  > div{
    margin: -5px -5px -5px 169px;
    padding: 5px;
    position: relative;

    > button{
      position: absolute;
      right: 5px;
      top: 5px;
      width: 40px;
      height: 40px;
      font-size: 16px;
      color: #666;

      &:hover{
        color: #333;
      }

      > svg{
        width: 18px;
        height: 18px;
        display: inline-block;
        vertical-align: middle;
        margin: -5px 0 0 0;
      }
    }

    > .hash{
      position: absolute;
      top: 5px;
      left: 5px;
      height: 40px;
      width: 40px;
      text-align: center;
      line-height: 40px;
      background: #CCC;
      color: #FFF;
      border-radius: 5px 0 0 5px;
      font-size: 14px;
      transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -webkit-transition: all 200ms ease-in-out;

      > svg{
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  input{
    border: 1px solid #CCC;
    height: 40px;
    border-radius: 5px;
    padding: 0 15px 0 55px;
    background: #FFF;
    font-size: 14px;

    &.has-val{
      padding: 0 40px 0 55px;
    }

    &:hover,
    &:focus{
      border: 1px solid #999;

      ~ .hash{
        background: #999;
      }
    }
  }
}

ul.list-province{
  padding: 20px;
  margin: -.5 0;

  > li{
    position: relative;
    padding: .5px 0;

    button{
      position: relative;
      background: #666;
      color: #FFF;
      text-align: left;
      padding: 10px 38px 10px 10px;
      font-size: 18px;
      line-height: 22px;
      z-index: 0;

      &:hover{
        background: #333;
      }

      &:before{
        content: '';
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translate(0, -50%);
        width: 10px;
        height: 10px;
        margin-top: -1px;
        background: #FFF;
        border-radius: 10px;
        opacity: 0;
        transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -webkit-transition: all 200ms ease-in-out;
      }

      > ul{
        display: inline-block;
        vertical-align: middle;
        margin: -7.5px -10px 0 -10px;
        padding: 0 0 0 10px;

        > li{
          display: inline-block;
          padding: 0 10px;
          font-size: 12px;
          line-height: 16px;
          border-right: 1px solid rgba(255, 255, 255, .5);

          &:last-child{
            border-right: none;
          }
        }
      }

      > svg{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
        transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -webkit-transition: all 200ms ease-in-out;
      }

      > div{
        position: absolute;
        left: 14px;
        width: 2px;
        height: 0%;
        bottom: 0;
        background: #FFF;
        opacity: 0;
        transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -webkit-transition: all 200ms ease-in-out;
      }
    }

    > ul{
      padding: .5px 0 0 0;
      margin: 0 0 -.5px 0;
      
      > li{
        position: relative;
        padding: .5px 0;

        &:before{
          content: '';
          position: absolute;
          width: 2px;
          top: 0;
          bottom: 0;
          left: 14px;
          background: #FFF;
          z-index: 1;
          opacity: 0;
          transition: all 200ms ease-in-out;
          -moz-transition: all 200ms ease-in-out;
          -webkit-transition: all 200ms ease-in-out;
        }

        > button{
          background: #888;
          font-size: 16px;
          line-height: 20px;
          padding: 10px 38px 10px 40px;

          &:hover{
            background: #555;
          }

          &:before{
            left: 28px;
          }

          &:after{
            content: '';
            position: absolute;
            top: 0;
            height: 50%;
            left: 14px;
            width: 0;
            border-radius: 0 0 0 10px;
            border-left: 2px solid #FFF;
            border-bottom: 2px solid #FFF;
            box-sizing: border-box;
            opacity: 0;
            transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -webkit-transition: all 200ms ease-in-out;
          }

          > ul{
            margin: -5px -10px 0 -10px;
          }

          > div{
            position: absolute;
            left: 32px;
            width: 2px;
            height: 0;
            bottom: 0;
            background: #FFF;
            opacity: 0;
            transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -webkit-transition: all 200ms ease-in-out;
          }
        }

        > ul{
          padding: .5px 0 0 0;
          margin: 0 0 -.5px 0;
          
          > li{
            position: relative;
            padding: .5px 0;

            &:before{
              content: '';
              position: absolute;
              width: 2px;
              top: 0;
              bottom: 0;
              left: 32px;
              background: #FFF;
              z-index: 1;
              opacity: 0;
              transition: all 200ms ease-in-out;
              -moz-transition: all 200ms ease-in-out;
              -webkit-transition: all 200ms ease-in-out;
            }

            > div{
              position: relative;
              background: #AAA;
              color: #FFF;
              padding: 10px 65px 10px 60px;
              font-size: 14px;
              line-height: 18px;

              &:after{
                content: '';
                position: absolute;
                top: 0;
                height: 50%;
                left: 32px;
                width: 16px;
                border-radius: 0 0 0 10px;
                border-left: 2px solid #FFF;
                border-bottom: 2px solid #FFF;
                box-sizing: border-box;
                opacity: 0;
                transition: all 200ms ease-in-out;
                -moz-transition: all 200ms ease-in-out;
                -webkit-transition: all 200ms ease-in-out;
              }

              > span{
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translate(0, -50%);
                // background: #666;
                // padding: 5px 5px;
                font-size: 12px;
                line-height: 12px;
                font-weight: bold;
                border-radius: 2px;
              }
            }
          }
        }
      }
    }
    
    &.open{
      > button{
        padding: 10px 38px 10px 30px;

        &:before{
          opacity: 1;
        }
        
        > svg{
          transform: translate(0, -50%) rotate(180deg);
        }

        > div{
          height: 50%;
          opacity: 1;
        }
      }

      > ul{
        > li{
          &:before{
            opacity: 1;
          }

          &:last-child{
            &:before{
              display: none;
            }
          }

          > button{
            &:after{
              width: 16px;
              opacity: 1;
            }
          }

          &.open{
            > button{
              padding: 10px 38px 10px 48px;
      
              &:before{
                opacity: 1;
              }
        
              > svg{
                transform: translate(0, -50%) rotate(180deg);
              }

              > div{
                height: 50%;
                opacity: 1;
              }
            }
  
            > ul{
              > li{
                &:before{
                  opacity: 1;
                }
      
                &:last-child{
                  &:before{
                    display: none;
                  }
                }

                > div{
                  &:after{
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.empty{
  text-align: center;
  padding: 120px 0 110px 0;

  span{
    color: #666;
    display: block;
    font-size: 30px;
    line-height: 34px;
    margin: 0 0 10px 0;
  }

  strong{
    color: #666;
  }
}

.loading{
  text-align: center;
  padding: 100px 20px;
  background: #EEE;
  margin: 1px 0 0 0;
  // color: #FFF;

  > ul{
    width: 48px;
    height: 48px;
    margin: 0 auto;
    padding-bottom: 5px;
    // overflow: auto;

    > li{
      position: relative;
      float: left;
      width: 33.33%;
      height: 16px;

      > div{
        width: 12px;
        height: 12px;
        // background: #FFF;
        background: #BBB;
        border-radius: 3px;
        margin: 0 auto;
        animation: loading 1s infinite ease-in-out;
      }

      &:nth-child(2){
        > div{
          animation-delay: 0.1s;
        }
      }

      &:nth-child(3){
        > div{
          animation-delay: 0.2s;
        }
      }

      &:nth-child(4){
        > div{
          animation-delay: 0.3s;
        }
      }

      &:nth-child(5){
        > div{
          animation-delay: 0.4s;
        }
      }

      &:nth-child(6){
        > div{
          animation-delay: 0.5s;
        }
      }

      &:nth-child(7){
        > div{
          animation-delay: 0.6s;
        }
      }

      &:nth-child(8){
        > div{
          animation-delay: 0.7s;
        }
      }

      &:nth-child(9){
        > div{
          animation-delay: 0.8s;
        }
      }
    }
  }
}

@keyframes loading{
  0% { opacity: .25; transform: scale(1, 1); }
  50% { opacity: 1; transform: scale(1.2, 1.2); }
  100% { opacity: .25; transform: scale(1, 1); }
}

footer{
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  text-align: center;
  padding: 20px 40px 40px 40px;
  font-size: 11px;
  line-height: 15px;
  background: rgba(255, 255, 255, .9);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);

  a{
    font-weight: bold;

    &:hover{
      margin: 0 5px;
    }

    > svg{
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin: -3px 0 0 0;
    }
  }

  > div{
    margin: 2.5px 0 0 0;
    font-size: 10px;
    line-height: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){
  footer{
    padding: 20px 20px 40px 20px;
  }
}

@media screen and (max-width: 767px) and (orientation: portrait){
  .Toastify__toast-container{
    width: auto !important;
    left: 30px !important;
    right: 30px !important;

    .Toastify__toast{
      border-radius: 5px;
    }
  }

  header{
    > a{
      float: none;
      display: block;
      width: 154px;
      margin: 0 auto 10px auto;

      > svg{
        width: 100%;
      }
    }

    > div{
      margin: -5px;
    }
  }

  .pad-top{
    height: 120px;
  }

  ul.list-province{
    padding: 20px 20px 0 20px;

    > li{
      button{
        > ul{
          display: block;
          padding: 5px 0 0 0;
        }
      }
    }
  }

  footer{
    padding: 20px;
  }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/